<div class="home-content">
    <div class="text">
        <h3>
            Bienvenue sur la page du Crespinol, pastis artisanal fabriqué très localement dans le Tarn par Florence, une
            passionnée de boissons faites maison.
        </h3>
    </div>
    <div class="slider">
        <img [src]="slides[activeIndex].image">
        <button mat-icon-button class="prev" (click)="prev()">
            <mat-icon class="fas fa-chevron-left"></mat-icon>
        </button>
        <button mat-icon-button class="next" (click)="next()">
            <mat-icon class="fas fa-chevron-right"></mat-icon>
        </button>
        <div class="indicators">
            <mat-icon *ngFor="let slide of slides; index as i" [ngClass]="{'fas': i === activeIndex, 'far': i !== activeIndex}"
                class="fa-circle" (click)="to(i)"></mat-icon>
        </div>
    </div>