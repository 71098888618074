<div class="sell-point">
     <div class="map" leaflet (leafletMapReady)="onMapReady($event)" [leafletOptions]="mapOptions">>
     </div>
     <div class="map-overlay">
          <mat-icon>shopping_cart</mat-icon>
          <div>
               Certains revendeurs proposent la vente en ligne
          </div>
     </div>
     <ul>
          <li *ngFor="let m of salePoints">
               <mat-card matRipple (click)="openMarker(m)">
                    <div class="card-header">
                         <p>
                              {{m.title}}

                         </p>
                    </div>
                    <mat-card-content class="last-child">
                         <div>
                              <p>
                                   {{m.adresse}}<br />
                                   {{m.zipCode + ' ' + m.city}}
                              </p>
                              <p>
                                   {{m.phone}}
                              </p>
                              <div *ngIf="m.site">
                                   <a href="{{'https://' + m.site}}" target="_blank">{{m.site}}</a>
                                   <div *ngIf="m.onlineSales">
                                        <mat-icon>shopping_cart</mat-icon>
                                        <span>Ce revendeur propose la vente en ligne</span>
                                   </div>
                              </div>
                         </div>
                    </mat-card-content>
               </mat-card>
          </li>
     </ul>
</div>