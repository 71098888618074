import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

export enum SCREEN_SIZE {
    XS,
    SM,
    MD,
    XL,
}

@Injectable()
export class ResponsiveService implements OnDestroy {
    private _unsubscriber$: Subject<any> = new Subject();
    public screenWidth$: BehaviorSubject<number> = new BehaviorSubject(null);
    public isMobile$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    public isTablet$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    public mediaBreakpoint$: BehaviorSubject<SCREEN_SIZE> = new BehaviorSubject(
        null
    );
    constructor() { }

    init() {
        if (typeof window !== "undefined") {
            this._setScreenWidth(window.innerWidth);
            this._setMediaBreakpoint(window.innerWidth);
            fromEvent(window, 'resize')
                .pipe(debounceTime(50), takeUntil(this._unsubscriber$))
                .subscribe((evt: any) => {
                    this._setScreenWidth(evt.target.innerWidth);
                    this._setMediaBreakpoint(evt.target.innerWidth);
                });
        }

    }

    ngOnDestroy() {
        this._unsubscriber$.next();
        this._unsubscriber$.complete();
    }

    private _setScreenWidth(width: number): void {
        this.screenWidth$.next(width);
    }

    private _setIsMobile(isMobile: boolean): void {
        this.isMobile$.next(isMobile);
    }

    private _setIsTablet(isTablet: boolean): void {
        this.isTablet$.next(isTablet);
    }

    private _setMediaBreakpoint(width: number): void {
        if (width < 860) {
            this._setIsMobile(true);
            this._setIsTablet(false);
        } else if (width >= 860 && width < 1310) {
            this._setIsMobile(false);
            this._setIsTablet(true);
        } else {
            this._setIsMobile(false);
            this._setIsTablet(false);
        }
    }
}