<div class="page">
  <ng-container *ngIf="isLanding">
    <app-landing></app-landing>
  </ng-container>
  <ng-container *ngIf="!isLanding">
    <mat-toolbar>
      <a [routerLink]="'/'">
        <img class="logo" src="../assets/logo.png" alt="Logo">
      </a>


      <div *ngIf="!(responsiveService.isMobile$ | async)" class="menu">
        <button mat-button [routerLink]="'/accueil'">ACCUEIL</button>
        <button mat-button [routerLink]="'/pastis-crespinol'">PASTIS LE CRESPINOL</button>
        <button mat-button [routerLink]="'/points-vente'">POINTS DE VENTE</button>
        <button mat-button [routerLink]="'/contact'">CONTACT</button>
      </div>

      <button *ngIf="(responsiveService.isMobile$ | async)" mat-icon-button [matMenuTriggerFor]="menu"
        (click)="toggelMenu()">
        <mat-icon *ngIf="!showMenu">menu</mat-icon>
        <mat-icon *ngIf="showMenu">close</mat-icon>
      </button>

      <a *ngIf="!(responsiveService.isMobile$ | async)" mat-mini-fab
        href="https://www.facebook.com/latelierdupuechferrat/" target="_blank" color="primary"
        aria-label="Example icon button with a menu icon">
        <mat-icon class="fab fa-facebook-f"></mat-icon>
      </a>
    </mat-toolbar>
    <mat-toolbar *ngIf="showMenu" class="burger-menu">
      <button mat-button [routerLink]="'/accueil'">ACCUEIL</button>
      <button mat-button [routerLink]="'/pastis-crespinol'">PASTIS LE CRESPINOL</button>
      <button mat-button [routerLink]="'/points-vente'">POINTS DE VENTE</button>
      <button mat-button [routerLink]="'/contact'">CONTACT</button>
    </mat-toolbar>

    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </ng-container>
</div>