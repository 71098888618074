import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResponsiveService, SCREEN_SIZE } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  counter: number = 0;
  interval: any;
  size: number = 1000;
  activeIndex: number = 0;
  slides = [
    { 'image': '../../../assets/carousel/slide1.jpg', 'isActive': true },
    { 'image': '../../../assets/carousel/slide2.jpg', 'isActive': false },
    { 'image': '../../../assets/carousel/slide3.jpg', 'isActive': false },
  ];

  constructor(public responsiveService: ResponsiveService) { }

  ngOnInit(): void {
    this.interval = setInterval(() => { this.autoSlide() }, 1500);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  next(): void {
    if (this.activeIndex === this.slides.length - 1)
      this.activeIndex = 0;
    else
      this.activeIndex++

    this.counter = 0
  }

  prev(): void {
    if (this.activeIndex === 0)
      this.activeIndex = this.slides.length - 1;
    else
      this.activeIndex--;

    this.counter = 0
  }

  to(index: number): void {
    this.activeIndex = index;
    this.counter = 0
  }

  private autoSlide(): void {
    this.counter++;

    if (this.counter === 3) {
      this.next();
      this.counter = 0
    }
  }
}
