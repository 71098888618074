import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-pastis-crespinol',
  templateUrl: './pastis-crespinol.component.html',
  styleUrls: ['./pastis-crespinol.component.scss']
})
export class PastisCrespinolComponent implements OnInit {

  constructor(public responsiveService: ResponsiveService) { }

  ngOnInit(): void {
  }

}
