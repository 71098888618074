<p>
    Remerciements aux productrices de plantes pour leur compétence et leur gentillesse :
</p>
<ul>
    <li>
        Stéphanie de "Puech Authentik",
    </li>
    <li>
        Corine et Eric de "La Lorien",
    </li>
    <li>
        Elodie de l’EURL Blanc de Ramaillou,
    </li>
    <li>
        Frédérica du "B.A.C.".
    </li>
</ul>
<p>
    Remerciements à :
</p>
<ul>
    <li>
        Mme Lasserre de la Chambre de Métiers et de l’Artisanat pour son accompagnement dynamique et professionnel,
    </li>
    <li>
        M. Beauville et M. Reilles du Crédit Mutuel pour leur accueil bienveillant,
    </li>
    <li>
        M. Delbreilh d’Initiative Tarn pour son soutien enthousiaste,
    </li>
    <li>
        Mme Chaillan des Douanes d’Albi pour sa patience,
    </li>
    <li>
        A l'équipe d'Agropoint / Saveurs du Tarn pour leurs conseils et leur appui.
    </li>
</ul>
<p>
    Remerciement particuliers et amicaux à :
</p>
<ul>
    <li>
        Sandra pour tout le travail de graphisme, sa compétence, ses conseils, ses retours toujours avisés,
    </li>
    <li>
        Vincent pour la construction de ce site et son plaisir à le faire,
    </li>
    <li>
        Emilien pour son accompagnement lors de mes premiers pas en comptabilité,
    </li>
    <li>
        Mireille et Michel pour le local et leurs conseils techniques,
    </li>
    <li>
        Victor, Caro, Maxime, Calou, Cyril, Emilie et Eric pour l’aide au bricolage,
    </li>
    <li>
        Nicolas, Manue et Cédric pour l’aide à réunir les ingrédients des premiers essais,
    </li>
    <li>
        Alexis et Clément pour leurs relectures et leur œil marketing,
    </li>
    <li>
        Et à toutes celles et ceux qui m’ont encouragée et soutenue dans ce projet, de quelque façon que ce soit !
    </li>
</ul>