<h1 class="slogan">
  Envolez-vous avec le Crespinol !<br />
  Faites évader vos papilles et échappez à votre routine
  anisée.
</h1>

<div class="multi-card">
  <ng-container *ngIf="!(responsiveService.isMobile$ | async) && !(responsiveService.isTablet$ | async)">
    <div class="col gutter-top">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Rural et naturel</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/rural.jpg">
        <mat-card-content>
          <p>
            La recette du Crespinol a été créée sur les flancs de la colline du Puech Ferrat dans la toute petite commune de
            Crespin pret d’Albi.<br />
            Fier d'être rural et naturel, il fait honneur aux aromatiques des prairies, haies et jardins comme
            l'armoise, la matricaire, le fenouil, la menthe, ... 19 plantes au total macèrent pendant 4 semaines
            pour un résultat fin et équilibré.<br />
            Il est fabriqué au rythme d'une micro-cuvée de 500 litres par an.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>D’où vient le Crespinol ?</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <img class="flo" src="./../../../assets/crespinol/flo.jpg">
          <p>
            <b>De 2003 à 2013</b> : Florence, passionnée de gastronomie, d’œnologie et de fait-maison, s’amuse
            régulièrement à décliner toutes les plantes et arbustes de son jardin (thym, verveine, tilleul,
            tanaisie, menthe, aubépine, …) en liqueurs, sirops ou apéritifs.
          </p>
          <p>
            <b>En 2013</b> : Elle a l’idée d’expérimenter son propre pastis, premiers essais, premières recettes.
          </p>
          <p>
            <b>De 2014 à 2019</b> : Florence poursuit ses essais, ses amis les testent. Elle commence à réfléchir à un
            mode de fabrication à petite échelle, budgète le projet, se renseigne sur les démarches
            administratives pour pouvoir le commercialiser.
          </p>
          <p>
            <b>2019 à 2020</b> : Encouragée par son entourage, elle prend la décision de franchir le pas et se lance
            dans l’aventure.
          </p>
        </mat-card-content>
      </mat-card>
      <div class="saveur-tarn">
        <img src="./../../../assets/crespinol/saveursDuTarn.jpg">
        <span>
          Le pastis le Crespinol est référencé "Saveurs du Tarn", la marque des produits locaux tarnais.
        </span>
      </div>
    </div>

    <div class="col gutter-top">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Aromatique et complexe</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/aromatique.jpg">
        <mat-card-content>
          <p>
            C'est un pastis intense qui offre une grande fraîcheur en bouche et des arômes complexes, où l'anis
            badiane et la réglisse n'occultent pas le bouquet des autres plantes, fleurs et graminées.<br />
            La recette définitive a été mise au point après plusieurs années d’essais de dosage de plantes, pour
            tirer le meilleur parti de chaque arôme sans qu’un goût ne ressorte au détriment d’un autre.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>Les ingrédients</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/ingredients.jpg">
        <mat-card-content>
          <p>
            Alcool, eau, macérats de plantes, sucre, anéthol naturel de badiane.<br />
            Sa coloration naturelle est obtenue uniquement par la macération des plantes, et peut légèrement
            varier d’une cuvée à l’autre.
          </p>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col gutter-top">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Local et éco-responsable</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/local.jpg">
        <mat-card-content>
          <p>
            Hormis l’anis badiane, le Crespinol a renoncé aux épices lointaines pour se fournir autant que c’est
            possible
            en circuit court auprès des petites productrices locales en PPAM (plantes médicinales, aromatiques et à
            parfum).<br />
            La très grande majorité des fournisseurs est tarnaise : Verrerie Ouvrière d’Albi, Rover Embal à Gaillac,
            imprimerie Rhode à Gaillac, Ménajex à Albi, etc.<br />
            Tous les contenants ou ingrédients achetés dans le cadre de la fabrication ou de la commercialisation sont
            recyclables (papier kraft, bidons en PET, cartons…), la totalité des déchets (plantes) est compostée dans le
            jardin de Florence, la créatrice.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>Comment le boire ?</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/boire.jpg">
        <mat-card-content>
          <p>
            Le Crespinol est un pastis de dégustation qui ravira les amateurs d'anisés.<br />
            Pour apprécier ses arômes, diluez une dose dans 6 à 10 volumes d'eau pure et bien fraîche.<br />
            Vous pouvez aussi le siroter pur comme digestif, dans un tout petit verre, ou bien en laisser tomber
            quelques gouttes dans un café expresso.
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>

  <ng-container *ngIf="(responsiveService.isTablet$ | async)">
    <div class="col gutter-top">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Rural et naturel</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/rural.jpg">
        <mat-card-content>
          <p>
            Le Crespinol, est né sur les flancs de la colline du Puech Ferrat dans la toute petite commune de
            Crespin au nord-est d’Albi.<br />
            Fier dêtre rural et naturel, il fait honneur aux aromatiques des prairies, haies et jardins comme
            l'armoise, la matricaire, le fenouil, la menthe, ... 19 plantes au total macèrent pendant 4 semaines
            pour un résultat fin et équilibré.<br />
            Tous les deux mois il est fabriqué en micro-cuvées de 400 litres seulement.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>Local et éco-responsable</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/local.jpg">
        <mat-card-content>
          <p>
            Hormis l’anis badiane, le Crespinol a renoncé aux épices lointaines pour se fournir autant que c’est
            possible
            en circuit court auprès des petites productrices locales en PPAM (plantes médicinales, aromatiques et à
            parfum).<br />
            La très grande majorité des fournisseurs est tarnaise : Verrerie Ouvrière d’Albi, Rover Embal à Gaillac,
            imprimerie Rhode à Gaillac, Ménajex à Albi, etc.<br />
            Tous les contenants ou ingrédients achetés dans le cadre de la fabrication ou de la commercialisation sont
            recyclables (papier kraft, bidons en PET, cartons…), la totalité des déchets (plantes) est compostée dans le
            jardin de Florence, la créatrice.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>Comment le boire ?</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/boire.jpg">
        <mat-card-content>
          <p>
            Le Crespinol est un pastis de dégustation qui ravira les amateurs d'anisés.<br />
            Pour apprécier ses arômes, diluez une dose dans 6 à 10 volumes d'eau pure et bien fraîche.<br />
            Vous pouvez aussi le siroter pur comme digestif, dans un tout petit verre, ou bien en laisser tomber
            quelques gouttes dans un café expresso.
          </p>
        </mat-card-content>
      </mat-card>

      <div class="saveur-tarn">
        <img src="./../../../assets/crespinol/saveursDuTarn.jpg">
        <span>
          Le pastis le Crespinol est référencé "Saveurs du Tarn", la marque des produits locaux tarnais.
        </span>
      </div>
    </div>
    <div class="col gutter-top">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Aromatique et complexe</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/aromatique.jpg">
        <mat-card-content>
          <p>
            C'est un pastis intense qui offre une grande fraîcheur en bouche et des arômes complexes, où l'anis
            badiane et la réglisse n'occultent pas le bouquet des autres plantes, fleurs et graminées.<br />
            La recette définitive a été mise au point après plusieurs années d’essais de dosage de plantes, pour
            tirer le meilleur parti de chaque arôme sans qu’un goût ne ressorte au détriment d’un autre.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>D’où vient le Crespinol ?</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <img class="flo" src="./../../../assets/crespinol/flo.jpg">
          <p>
            <b>De 2003 à 2013</b> : Florence, passionnée de gastronomie, d’œnologie et de fait-maison, s’amuse
            régulièrement à décliner toutes les plantes et arbustes de son jardin (thym, verveine, tilleul,
            tanaisie, menthe, aubépine, …) en liqueurs, sirops ou apéritifs.
          </p>
          <p>
            <b>En 2013</b> : Elle a l’idée d’expérimenter son propre pastis, premiers essais, premières recettes.
          </p>
          <p>
            <b>De 2014 à 2019</b> : Florence poursuit ses essais, ses amis les testent. Elle commence à réfléchir à un
            mode de fabrication à petite échelle, budgète le projet, se renseigne sur les démarches
            administratives à faire pour pouvoir le commercialiser.
          </p>
          <p>
            <b>2019 à 2020</b> : Encouragée par son entourage, elle prend la décision de franchir le pas et se lance
            dans l’aventure.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>Les ingrédients</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/ingredients.jpg">
        <mat-card-content>
          <p>
            Alcool, eau, macérats de plantes, sucre, anéthol naturel de badiane.<br />
            Sa coloration naturelle est obtenue uniquement par la macération des plantes, et peut légèrement
            varier d’une cuvée à l’autre.
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>

  <ng-container *ngIf="(responsiveService.isMobile$ | async) ">
    <div class="col gutter-top">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Rural et naturel</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/rural.jpg">
        <mat-card-content>
          <p>
            Le Crespinol, est né sur les flancs de la colline du Puech Ferrat dans la toute petite commune de
            Crespin au nord-est d’Albi.<br />
            Fier dêtre rural et naturel, il fait honneur aux aromatiques des prairies, haies et jardins comme
            l'armoise, la matricaire, le fenouil, la menthe, ... 19 plantes au total macèrent pendant 4 semaines
            pour un résultat fin et équilibré.<br />
            Tous les deux mois il est fabriqué en micro-cuvées de 400 litres seulement.
          </p>
        </mat-card-content>
      </mat-card>
      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>Aromatique et complexe</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/aromatique.jpg">
        <mat-card-content>
          <p>
            C'est un pastis intense qui offre une grande fraîcheur en bouche et des arômes complexes, où l'anis
            badiane et la réglisse n'occultent pas le bouquet des autres plantes, fleurs et graminées.<br />
            La recette définitive a été mise au point après plusieurs années d’essais de dosage de plantes, pour
            tirer le meilleur parti de chaque arôme sans qu’un goût ne ressorte au détriment d’un autre.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>Local et éco-responsable</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/local.jpg">
        <mat-card-content>
          <p>
            Hormis l’anis badiane, le Crespinol a renoncé aux épices lointaines pour se fournir autant que c’est
            possible
            en circuit court auprès des petites productrices locales en PPAM (plantes médicinales, aromatiques et à
            parfum).<br />
            La très grande majorité des fournisseurs est tarnaise : Verrerie Ouvrière d’Albi, Rover Embal à Gaillac,
            imprimerie Rhode à Gaillac, Ménajex à Albi, etc.<br />
            Tous les contenants ou ingrédients achetés dans le cadre de la fabrication ou de la commercialisation sont
            recyclables (papier kraft, bidons en PET, cartons…), la totalité des déchets (plantes) est compostée dans le
            jardin de Florence, la créatrice.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>D’où vient le Crespinol ?</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <img class="flo" src="./../../../assets/crespinol/flo.jpg">
          <p>
            <b>De 2003 à 2013</b> : Florence, passionnée de gastronomie, d’œnologie et de fait-maison, s’amuse
            régulièrement à décliner toutes les plantes et arbustes de son jardin (thym, verveine, tilleul,
            tanaisie, menthe, aubépine, …) en liqueurs, sirops ou apéritifs.
          </p>
          <p>
            <b>En 2013</b> : Elle a l’idée d’expérimenter son propre pastis, premiers essais, premières recettes.
          </p>
          <p>
            <b>De 2014 à 2019</b> : Florence poursuit ses essais, ses amis les testent. Elle commence à réfléchir à un
            mode de fabrication à petite échelle, budgète le projet, se renseigne sur les démarches
            administratives à faire pour pouvoir le commercialiser.
          </p>
          <p>
            <b>2019 à 2020</b> : Encouragée par son entourage, elle prend la décision de franchir le pas et se lance
            dans l’aventure.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>Comment le boire ?</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/boire.jpg">
        <mat-card-content>
          <p>
            Le Crespinol est un pastis de dégustation qui ravira les amateurs d'anisés.<br />
            Pour apprécier ses arômes, diluez une dose dans 6 à 10 volumes d'eau pure et bien fraîche.<br />
            Vous pouvez aussi le siroter pur comme digestif, dans un tout petit verre, ou bien en laisser tomber
            quelques gouttes dans un café expresso.
          </p>
        </mat-card-content>
      </mat-card>

      <mat-card class="gutter-top">
        <mat-card-header>
          <mat-card-title>Les ingrédients</mat-card-title>
        </mat-card-header>
        <img mat-card-image src="./../../../assets/crespinol/ingredients.jpg">
        <mat-card-content>
          <p>
            Alcool, eau, macérats de plantes, sucre, anéthol naturel de badiane.<br />
            Sa coloration naturelle est obtenue uniquement par la macération des plantes, et peut légèrement
            varier d’une cuvée à l’autre.
          </p>
        </mat-card-content>
      </mat-card>
      <div class="saveur-tarn">
        <img src="./../../../assets/crespinol/saveursDuTarn.jpg">
        <span>
          Le pastis le Crespinol est référencé "Saveurs du Tarn", la marque des produits locaux tarnais.
        </span>
      </div>
    </div>
  </ng-container>

  <div class="visit">Pas de visite du local de fabrication ni de vente sur place, pour se procurer le pastis le Crespinol, veuillez consulter la liste des points de vente.</div>
</div>