<div *ngIf="!(responsiveService.isMobile$ | async)" class="footer">
    <div class="disclaimer">
        L'abus d'alcool est dangereux pour la santé, à consommer avec modération.
    </div>
    <div class="footer-content">
        <a routerLink="mentions-legales">Mentions Légales</a>
        <div>
            Design du logo et de l'identité visuelle du Crespinol : Sandra Cantaloube.<br />
            Site réalisé par : Vincent Marty
        </div>
        <a routerLink="remerciements">Remerciements</a>
    </div>
</div>
<div *ngIf="(responsiveService.isMobile$ | async)" class="footer-mobile">
    <div class="disclaimer">
        L'abus d'alcool est dangereux pour la santé, à consommer avec modération.
    </div>
    <div class="footer-content">
        <div class="footer-content-container">
            <div class="footer-content-container-action">
                <a routerLink="mentions-legales">Mentions Légales</a>
                <a routerLink="remerciements">Remerciements</a>
            </div>
            <a mat-mini-fab class="footer-content-container-social" href="https://www.facebook.com/latelierdupuechferrat/" target="_blank" color="primary"
                aria-label="Example icon button with a menu icon">
                <mat-icon class="fab fa-facebook-f"></mat-icon>
            </a>
        </div>
        <div class="footer-content-text">
            Design du logo et de l'identité visuelle du Crespinol : Sandra Cantaloube.<br />
            Site réalisé par : Vincent Marty
        </div>
    </div>
</div>