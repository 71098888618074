import { Component, OnInit } from '@angular/core';
import { latLng, MapOptions, tileLayer, Map, Marker, icon, Point, Circle, Polygon } from 'leaflet';
import salePointsJson from '../../../assets/salePoints.json'

interface SALEPOINT {
  id: string;
  lat: number;
  lng: number;
  title: string;
  adresse: string;
  city: string;
  zipCode: string;
  phone?: string;
  site?: string;
  onlineSales: boolean;
}

@Component({
  selector: 'app-points-vente',
  templateUrl: './points-vente.component.html',
  styleUrls: ['./points-vente.component.scss']
})
export class PointsVenteComponent implements OnInit {
  map: Map;
  mapOptions: MapOptions;
  markers = new Array();
  salePoints: SALEPOINT[] = salePointsJson.sort((a, b) =>
    a.city.localeCompare(b.city) || a.title.localeCompare(b.title));

  constructor() {
  }

  ngOnInit() {
    this.initializeMapOptions();
  }

  onMapReady(map: Map) {
    this.map = map;
    this.addSampleMarker();
  }

  private initializeMapOptions() {
    this.mapOptions = {
      center: latLng(43.88, 2.13),
      zoom: 10,
      scrollWheelZoom: true,
      layers: [
        tileLayer(
          'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            maxZoom: 18,
            attribution: 'Map data © OpenStreetMap contributors'
          })
      ],
    };
  }

  private addSampleMarker() {
    this.salePoints.forEach(m => {
      const marker = new Marker([m.lat, m.lng], { title: m.id })
        .setIcon(
          icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png'
          }))
        .bindPopup(`<b>${m.title}</b><br>
          ${m.adresse} ${m.zipCode} ${m.city}<br>
          ${m.phone}
          ${m.site ? "<br><a href='https://" + m.site + "' target='_blank' style='color: black;'>" + m.site + "</a>" : ''}`, { offset: new Point(0, -30) })
        .addTo(this.map);
      this.markers.push(marker);
    })
  }

  public openMarker(pdv) {
    for (var i in this.markers) {
      var markerID = this.markers[i].options.title;
      if (markerID == pdv.id) {
        this.markers[i].openPopup();
      };
    }
    this.map.setView([pdv.lat, pdv.lng], 14);


    window.scroll({
      top: 97,
      left: 0,
      behavior: 'smooth'
    });
  }

}
