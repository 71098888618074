<div class="contact-container">
    <div class="contact">
        <h1>Me contacter</h1>
        <p>
            Une question ? Un renseignement ? Envie de distribuer le Crespinol ? Des cadeaux d’entreprise à faire
            ?<br />
            N’hésitez pas à me contacter, je vous répondrai dans les plus brefs délais.
        </p>
        <div>
            <a mat-mini-fab class="footer-content-container-social" href="https://www.facebook.com/latelierdupuechferrat/"
            target="_blank" color="primary">
            <mat-icon class="fab fa-facebook-f"></mat-icon>
            </a>
            <a mat-mini-fab class="footer-content-container-social" href="mailto:latelierdupuechferrat@mailo.com"
            color="primary" >
                <mat-icon>email</mat-icon>
            </a>
        </div>
       
    </div>
    <!-- <mat-divider *ngIf="!(responsiveService.isMobile$ | async)" vertical></mat-divider>
    <div class="contact-form">
        <form>
            <div class="multi-field">
                <mat-form-field>
                    <mat-label>Nom</mat-label>
                    <input type="text" matInput>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Prénom</mat-label>
                    <input type="text" matInput>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <mat-label>E-mail</mat-label>
                    <input type="email" matInput>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <mat-label>Message</mat-label>
                    <textarea rows="5" matInput></textarea>
                </mat-form-field>
            </div>
            <div class="action">
                <button mat-raised-button (click)="sendMail()">Envoyer</button>
            </div>
        </form>
    </div> -->
</div>