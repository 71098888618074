<h1>Mentions légales</h1>
<p>En vigueur au 14/10/2020</p>
<p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans
    l’économie numérique, dite L.C.E.N., il est porté à la connaissance des Utilisateurs du site
    www.latelierdupuechferrat.fr les présentes mentions légales.
</p>
<p>
    La connexion et la navigation sur le site www.latelierdupuechferrat.fr par l’Utilisateur implique acceptation
    intégrale et sans réserve des présentes mentions légales.
</p>
<h2>ARTICLE 1 : L’éditeur</h2>
<p>
    L’édition et la direction de la publication du site www.latelierdupuechferrat.fr sont assurées par Florence
    Carbonnel, domiciliée à Cadoul 81350 Crespin, dont le numéro de téléphone est 06.86.54.86.55, et l'adresse e-mail
    latelierdupuechferrat@mailo.com<br />
    <br />
    SIRET: 885 065 243 000 10<br />
    APE : 1101Z<br />
    Répertoire des métiers : 885 065 243 RM 810<br />
    Accise : FR020003N8271<br />
</p>
<h2>ARTICLE 2 : L’hébergeur</h2>
<p>
    L'hébergeur du site www.latelierdupuechferrat.fr est la Société SARL o2switch, 222-224 Boulevard Gustave
    Flaubert, 63000 Clermont-Ferrand, tél : 04.44.44.60.40<br>
    <br>
    SIRET : 510 909 807 00024<br />
    RCS Clermont Ferrand
</p>
<h2>ARTICLE 3 : Accès au site</h2>
<p>
    Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou
    non et pouvant découlant d’une nécessité de maintenance.
</p>
<p>
    En cas de modification, interruption ou suspension des services le site www.latelierdupuechferrat.fr ne saurait être
    tenu
    responsable.
</p>
<h2>ARTICLE 4 : Collecte des données</h2>
<p>
    Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il ne
    collecte aucune donnée concernant les utilisateurs.
</p>
<h2>ARTICLE 5 : Propriété intellectuelle</h2>
<p>
    Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du site test,
    sans autorisation de l’Editeur est prohibée et pourra entraîner des actions et poursuites judiciaires telles que
    notamment celles prévues par le Code de la propriété intellectuelle et le Code civil.
</p>