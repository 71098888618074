<div class="landing-page">
    <div [ngClass]="crespinol"></div>
    <div class="section lamp"></div>
    <div class="test">
        <div (click)="goTo('/crespinol')"  (mouseover)="changeStyleCrespinol($event)" (mouseout)="changeStyleCrespinol($event)">
            <img *ngIf="!(responsiveService.isMobile$ | async) && !(responsiveService.isTablet$ | async)"
                src="../../../assets/landing/logo-crespinol.png" alt="Luminous logo" height="587px" />
            <img *ngIf="(responsiveService.isMobile$ | async) || (responsiveService.isTablet$ | async)"
                src="../../../assets/landing/logo-crespinol.png" alt="Luminous logo" height="300px" />
        </div>
        <div class="luminous">
            <img *ngIf="!(responsiveService.isMobile$ | async) && !(responsiveService.isTablet$ | async)"
                src="../../../assets/landing/logo-luminous.png" alt="Luminous logo" width="591px" />
            <img *ngIf="(responsiveService.isMobile$ | async) || (responsiveService.isTablet$ | async)"
                src="../../../assets/landing/logo-luminous.png" alt="Luminous logo" width="296px" />
            <div>
                Site en cours d'actualisation, merci de votre compréhension.
            </div>
        </div>
    </div>
    <div class="logo" *ngIf="!(responsiveService.isMobile$ | async) && !(responsiveService.isTablet$ | async)">
        <img src="../../../assets/landing/logo.png" width="300px" alt="Logo" />
    </div>
    <div class="logo logo-mobile"
        *ngIf="(responsiveService.isMobile$ | async) || (responsiveService.isTablet$ | async)">
        <img src="../../../assets/landing/logo.png" width="100px" alt="Logo" />
    </div>
</div>