import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ResponsiveService } from './services/responsive.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'AtelierPuechFerrat'
  showMenu: boolean = false;
  isLanding: boolean = true;

  constructor(public responsiveService: ResponsiveService, router: Router) {
    this.responsiveService.init();

    router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.isLanding = event.urlAfterRedirects === '' || event.urlAfterRedirects === '/' ? true : false;
      }
    })

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.showMenu = false;
      }
    });
  }

  ngOnInit(): void {
  }

  toggelMenu(): void {
    this.showMenu = !this.showMenu;
  }
}
