import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {
  crespinol: string = 'section pastis';

  constructor(private router: Router, public responsiveService: ResponsiveService) { }

  goTo(url: string) {
    this.router.navigate([url]);
  }

  changeStyleCrespinol($event) {
    this.crespinol = $event.type == 'mouseover' ? 'section pastis blur' : 'section pastis';
  }
}
