import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { PastisCrespinolComponent } from './features/pastis-crespinol/pastis-crespinol.component';
import { PointsVenteComponent } from './features/points-vente/points-vente.component';
import { RemerciementsComponent } from './features/remerciements/remerciements.component';
import { ContactComponent } from './features/contact/contact.component';
import { MentionsLegalesComponent } from './features/mentions-legales/mentions-legales.component';

const routes: Routes = [
  {
    path: 'crespinol', component: HomeComponent
  },
  {
    path: 'accueil', redirectTo: 'crespinol'
  },
  {
    path: 'pastis-crespinol', component: PastisCrespinolComponent
  },
  {
    path: 'points-vente', component: PointsVenteComponent
  },
  {
    path: 'contact', component: ContactComponent
  },
  {
    path: 'remerciements', component: RemerciementsComponent
  },
  {
    path: 'mentions-legales', component: MentionsLegalesComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
