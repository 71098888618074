import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(public responsiveService: ResponsiveService, private http: HttpClient) { }

  ngOnInit(): void {
  }


}
